// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCWzx6g2Qmqdr-6lDt4pgjJJDBZBfAsFP0",
  authDomain: "isdn-2602-iot.firebaseapp.com",
  databaseURL: "https://isdn-2602-iot-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "isdn-2602-iot",
  storageBucket: "isdn-2602-iot.appspot.com",
  messagingSenderId: "439990123989",
  appId: "1:439990123989:web:a55e9e77088f2c0d8e915c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;