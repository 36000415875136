import { Typography } from "@mui/material";

const TrafficLight = ({ value, intersection, light }) => {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        background: 'rgba(255, 255, 255, 0.5)', 
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', 
        padding: '10px',
      }}>
        <div style={{ 
          display: 'flex', 
          textAlign: 'center', 
          margin: '0 20px', 
          flexDirection: "column", 
          alignItems: "center" 
        }}>
          <Typography variant="h6" gutterBottom>
            Intersection {intersection}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Traffic Light {light}
          </Typography>
          <div style={{ display: 'block', justifyContent: 'center', margin: 'auto' }}>
            <div style={{ 
              width: '30px', 
              height: '30px', 
              borderRadius: '50%', 
              margin: '5px', 
              background: value === "red" ? "red" : "grey"
            }}></div>
            <div style={{ 
              width: '30px', 
              height: '30px', 
              borderRadius: '50%', 
              margin: '5px', 
              background: value === "yellow" ? "yellow" : "grey"
            }}></div>
            <div style={{ 
              width: '30px', 
              height: '30px', 
              borderRadius: '50%', 
              margin: '5px',
              background: value === "green" ? "green" : "grey"
              }}></div>
          </div>
        </div>
      </div>
    );
  };

  export default TrafficLight;