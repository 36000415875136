import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

const Timer = forwardRef(({ time, onTick }, ref) => {
    const targetTime = useRef(time);
    const currentTime = useRef(time);
    const timerRunning = useRef(false);
    const timerLabel = useRef();

    const timeString = (t) => {
        return `${Math.floor(t / 60).toString().padStart(2, "0")}:${(t % 60).toString().padStart(2, "0")}`;
    }
    
    useImperativeHandle(ref, () => ({
        startTimer() {
            console.log('Timer started');
            timerRunning.current = true;
            timerLabel.current.style.color = "green";
        },
        pauseTimer() {
            console.log('Timer paused');
            timerRunning.current = false;
            timerLabel.current.style.color = "black";
        },
        resetTimer() {
            console.log('Timer reset');
            timerRunning.current = false;
            currentTime.current = targetTime.current;
            timerLabel.current.textContent = timeString(time);
            timerLabel.current.style.color = "black";
        },
        setNewTime(newTime) {
            console.log('Set new time');
            timerRunning.current = false;
            targetTime.current = newTime;
            currentTime.current = newTime;
            timerLabel.current.textContent = timeString(newTime);
            timerLabel.current.style.color = "black";
        },
        getTime() {
            return currentTime.current;
        }
    }));

    useEffect(() => {
        console.log('Timer mounted');

        const timerInterval = setInterval(() => {
            if (timerRunning.current) {
                if (currentTime.current > 0) {
                    currentTime.current--;
                    timerLabel.current.textContent = timeString(currentTime.current);
                    if (currentTime.current <= 10) {
                        timerLabel.current.style.color = "red";
                    }
                    onTick(currentTime.current);
                }
            }
        }, 1000);

        return () => {
            console.log('Timer unmounted');
            clearInterval(timerInterval);
        };
    }, []);

    return (
        <h1 ref={timerLabel}>{timeString(time)}</h1>
    );
});

export default Timer;