import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Typography, Container, Grid, Button, Select, MenuItem } from '@mui/material';
import backgroundImg from './Background.png';
import Timer from '../components/Timer';
import TrafficLight from '../components/TrafficLight';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, onValue, update, get } from "firebase/database";
import { red } from '@mui/material/colors';

const db = getDatabase();

const HomePage = () => {
  const navigate = useNavigate();

  console.log(useAuth());
  const { userLoggedIn } = useAuth(); // Using the useAuth hook to get the user authentication status

  const timerRef = useRef();
  const [task, setTask] = useState(1);
  const [startPt, setStartPt] = useState(1);
  const [endPt, setEndPt] = useState(1);
  const [taskState, setTaskState] = useState("stop");
  const [trafficLightStates, setTrafficLightStates] = useState([0, 0, 0, 0, 0]);
  const currentTaskRef = useRef(task);

  useEffect(() => {
    console.log("Task:", task);
    currentTaskRef.current = task;
    // Perform actions that depend on the updated task state here
    updateTaskOnRTDB(task);
    if (task === 1 || task === 2) {
      setStartPt(11);
      setEndPt(5);
      updateStartEndPointsOnRTDB(11, 5);
    } else if (task === 3) {
      // Task 3 logic
    } else if (task === 4) {
      // Task 4 logic
    }

  }, [task]);

  useEffect(() => {
    const rtdbRef = ref(db, '/intersections');
    onValue(rtdbRef, (snapshot) => {
      const data = snapshot.val();
      console.log("Data from RTDB:", data); // Console log the data
      let tmp = Object.values(data).map((value) => {
        const lights = Object.values(value.traffic_lights);
        return [lights[0].current_state, lights[1].current_state];
      });
      console.log("Traffic Light States:", tmp);
      setTrafficLightStates(tmp);
    });
  }, []);

  const startTimer = () => {
    console.log("Start task: ", task)
    timerRef.current?.startTimer();
    setTaskState("start");
    updateExamStateOnRTDB("started");
  }

  const pauseTimer = () => {
    timerRef.current?.pauseTimer();
    setTaskState("pause");
    updateExamStateOnRTDB("paused");
  }

  const stopTimer = () => {
    timerRef.current?.setNewTime(120 + (task > 2) * 60);
    setTaskState("stopped");
    updateExamStateOnRTDB("stopped");
  }

  useEffect(() => {
    timerRef.current.setNewTime(120 + (task > 2) * 60);
  }, [task])

  // Redirect to login page if user is not logged in
  useEffect(() => {
    if (!userLoggedIn) {
      if (!userLoggedIn) {
        navigate('/login');
      }
    }
  }, []);

  // Function to update start and end points on RTDB
  const updateStartEndPointsOnRTDB = (newStartPt, newEndPt) => {
    console.log("New start and end points:", newStartPt, newEndPt);
    update(ref(db, '/demo'), {
      start_point: newStartPt,
      end_point: newEndPt
    });
  }

  const updateTaskOnRTDB = (newTask) => {
    update(ref(db, '/demo'), {
      task: newTask
    });
  }

  const updateExamStateOnRTDB = (newExamState) => {
    if (newExamState === "stopped") {
      updateExamTimeOnRTDB(120 + (task > 2) * 60);
      // For loop for 5 time
      for (let i = 1; i <= 5; i++) {
        // Adda a value +1 to the current state of light 1
        update(ref(db, `/intersections/intersection_${i}/traffic_lights/light_1`), {
          current_state: "red",
          time_remain: 17
        });
        update(ref(db, `/intersections/intersection_${i}/traffic_lights/light_2`), {
          current_state: "green",
          time_remain: 15
        });
      }
    }
    // if (newExamState === "started") {
    //   // For loop for 5 time
    //   for (let i = 1; i <= 5; i++) {
    //     // Adda a value +1 to the current state of light 1
    //     update(ref(db, `/intersections/intersection_${i}/traffic_lights/light_1`), {
    //       current_state: "green",
    //       time_remain: 15
    //     });
    //     update(ref(db, `/intersections/intersection_${i}/traffic_lights/light_2`), {
    //       current_state: "red",
    //       time_remain: 17
    //     });
    //   }
    // }
    update(ref(db, '/demo'), {
      state: newExamState
    });
  }

  const updateExamTimeOnRTDB = async (newExamTime) => {
    // Update the global time_left
    update(ref(db, '/demo'), {
      time_left: newExamTime
    });
  };

  const updateTrafficLightOnRTDB = () => {
    console.log("Update Task:", currentTaskRef.current);
    const rtdbRef = ref(db, '/intersections');
    get(rtdbRef).then((snapshot) => {
      const data = snapshot.val();
      for (const intersectionId in data) {
        const intersection = data[intersectionId];
        for (const lightId in intersection.traffic_lights) {
          const light = intersection.traffic_lights[lightId];
          // Only update when task is not 1
          if (currentTaskRef.current === 1) {
            light.current_state = 'green';
            let newTime = 15;
            // Update time_remain and current_state for the traffic light
            update(ref(db, `/intersections/${intersectionId}/traffic_lights/${lightId}`), {
              time_remain: newTime,
              current_state: light.current_state
            });
          }
          else {
            let newTime = Math.max(0, light.time_remain - 1); // Ensure time_remain doesn't go below 0

            // Update light state if time reaches 0 and set new time accordingly
            switch (light.current_state) {
              case 'green':
                if (newTime === 0) {
                  light.current_state = 'yellow';
                  newTime = 2; // Set new time for Yellow
                }
                break;
              case 'yellow':
                if (newTime === 0) {
                  light.current_state = 'red';
                  newTime = 17; // Set new time for Red
                }
                break;
              case 'red':
                if (newTime === 0) {
                  light.current_state = 'green';
                  newTime = 15; // Set new time for Green
                }
                break;
              default:
                break;
            }

            // Update time_remain and current_state for the traffic light
            update(ref(db, `/intersections/${intersectionId}/traffic_lights/${lightId}`), {
              time_remain: newTime,
              current_state: light.current_state
            });
          }
        }
      }
    });
  };

  const handleTimerTick = (timeLeft) => {
    updateExamTimeOnRTDB(timeLeft);
    updateTrafficLightOnRTDB();
  };

  return (
    <div style={{
      backgroundImage: `url(${backgroundImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      // width: '100vw', 
      // height: '56.25vw',
      overflow: 'hidden'
    }}>
      <Container style={{ width: '100%', height: '100%', textAlign: 'center' }}>
        <Typography variant="h3" align="center" gutterBottom>
          ISDN 2602 Control Panel
        </Typography>
        <div>
          {trafficLightStates.map((value, index) => (
            <div key={index} style={{ display: 'inline-block' }}>
              <TrafficLight value={value[0]} intersection={index + 1} light={1} />
              <TrafficLight value={value[1]} intersection={index + 1} light={2} />
            </div>
          ))}
        </div>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={12} sm={4}>
            <div style={{
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              gap: "8px",
              height: "100%"
            }}>
              <Typography variant="h4" gutterBottom>Exam</Typography>
              <Button variant="contained" disabled={taskState === "start"} onClick={() => startTimer()}>Start</Button>
              <Button variant="contained" disabled={taskState !== "start"} onClick={() => pauseTimer()}>Pause</Button>
              <Button variant="contained" disabled={taskState === "stop"} color="error" onClick={() => stopTimer()}>Stop</Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div style={{
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              marginBottom: "20px",
            }}>
              <Typography variant="h4" gutterBottom>Timer</Typography>
              <Timer ref={timerRef} time={120} onTick={(timeLeft) => handleTimerTick(timeLeft)} />
            </div>
            <div style={{
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
            }}>
              <Typography variant="h4" gutterBottom>Task</Typography>
              <Select onChange={(e) => {
                const selectedTask = e.target.value;
                setTask(selectedTask);
              }}>
                <MenuItem value={1}>Task 1</MenuItem>
                <MenuItem value={2}>Task 2</MenuItem>
                <MenuItem value={3}>Task 3</MenuItem>
                <MenuItem value={4}>Task 4</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div style={{
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              gap: "8px",
              height: "100%"
            }}>
              <Typography variant="h4" gutterBottom>Start Pt</Typography>
              <input
                type='number'
                min='1'
                max='15'
                value={startPt}
                onChange={(e) => {
                  e.persist(); // Persist the event
                  const newStartPt = e.target.value;
                  setStartPt(newStartPt);
                  // Update start point on RTDB
                  updateStartEndPointsOnRTDB(newStartPt, endPt);
                }}
              />

              <Typography variant="h4" gutterBottom>End Pt</Typography>
              <input
                type='number'
                min='1'
                max='15'
                value={endPt}
                onChange={(e) => {
                  e.persist(); // Persist the event
                  const newEndPt = e.target.value;
                  setEndPt(newEndPt);
                  // Update end point on RTDB
                  updateStartEndPointsOnRTDB(startPt, newEndPt);
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Typography variant="body2" color="textSecondary">
          ISDN 2602 Dashboard Made by Jimmy Wu @ 2024 and Credit to Renault Luk
        </Typography>
      </Container>
    </div>
  );
};
export default HomePage;
