// App.js

import React, { useState, useEffect } from 'react';
import Home from './page/Home';
import Signup from './page/Signup';
import Login from './page/Login.js';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext/index.js';

function App() {

  return (
    <AuthProvider>
      <Router>
      <div>
        <section>
          <Routes>                                                                       
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </section>
      </div>
    </Router>
    </AuthProvider>
  );
}

export default App;